import { getBrowserData } from '@/react/utils/getBrowserData';
import type { PaymentFormModel } from '@/react/view-models/forms/checkout/Payment';
import type { ShippingFormModel } from '@/react/view-models/forms/checkout/Shipping';
import CartService from '@/services/isomorphic/CartService';
import ConfigurationService, {
  Config
} from '@/services/isomorphic/ConfigurationService';
import { EndlessAisleAgent } from '@/services/isomorphic/EndlessAisleService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { PaymentRequestService } from '@/services/isomorphic/PaymentRequestService';
import PlaceOrderService from '@/services/isomorphic/PlaceOrderService';
import UserInteractionService, {
  EventType
} from '@/services/isomorphic/UserInteractionService';
import type { IAddress } from '@/services/models/Address';
import { CartModel, ICart } from '@/services/models/Cart';
import type { ICoupon } from '@/services/models/Cart/Coupon';
import {
  ILineItem,
  LineItemModel,
  LineItemType,
  ProductLineItemModel
} from '@/services/models/Cart/LineItem';
import type { ICartPromotion } from '@/services/models/Cart/Promotion/Cart';
import type { GiftCardModel, IGiftCardBase } from '@/services/models/GiftCard';
import { MoneyModel } from '@/services/models/Money';
import type { IBrowserData, PlacedOrderModel } from '@/services/models/Order';
import type { IProduct } from '@/services/models/Product';
import type { ShippingMethodModel } from '@/services/models/ShippingMethod';
import type { IAuthenticatedUser } from '@/services/models/User/AuthenticatedUser';
import siteCached from '@/services/utils/siteCached';
import type { DTO, Nullable } from '@/type-utils';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import { ForbiddenActionError, InvalidStateError } from '@/utils/errors';
import { makeAutoObservable, when } from 'mobx';
import type { IGetExpressCheckoutDetailsResponse } from '@/services/serverless/integrations/ServerPayPalNVPService';
import PayPalNVPService from '@/services/isomorphic/integrations/PayPalNVPService';
import { UninitializedCartError } from './UninitializedCartError';

/**
 * The Cart view model. Unlike the {@link CartModel}, this view model is allowed to
 * have properties which describe presentational state. Moreover, while a cart
 * model isn't initialized until it's been created and retrieved from AWS,
 * the view model is initialized immediately, representing the user's idea
 * of a cart, that is, something they always have while shopping on the site.
 */
export default class CartVM implements ICart {
  /** The underlying cart model. This is `null` until the cart is initialized via AWS. */
  private cartModel: Nullable<CartModel>;

  /** Whether an order with the current cart has been placed. */
  private _isPlaced: boolean = false;

  /**
   * Whether an order with the current cart has been placed.
   * @returns `true` if an order has been placed, and `false` otherwise.
   */
  public get isPlaced(): boolean {
    return this._isPlaced;
  }

  /**
   * The cart config.
   * @returns A `Config<'cart'>`.
   */
  @siteCached
  private get cartConfig(): Config<'cart'> {
    const config = ConfigurationService.getConfig('cart');
    this.validateCartConfig(config); // this is only called once per "site" since this getter is siteCached
    return config;
  }

  /**
   * Whether the cart is ready to be used. This is necessary to check
   * before performing any operations on the cart.
   * @returns A boolean representing whether the cart is ready to be used.
   */
  public get isReady(): boolean {
    return !!this.cartModel;
  }

  /**
   * A promise that resolves when the cart is ready to be used.
   * This is useful for operations that require the cart to be fully initialized.
   * @returns A promise that resolves when the cart is ready.
   */
  private get untilReady(): Promise<void> {
    return when(() => this.isReady);
  }

  /**
   * Whether the cart is currently revalidating. Useful for operations that
   * require the most recent data available.
   *
   * @returns A boolean representing whether the cart is revalidating.
   */
  public get isRevalidating(): boolean {
    if (!this.cartModel) return false;

    const {
      applicableShippingMethods,
      shippingCost,
      linesDiscount,
      cartDiscount,
      discount,
      tax,
      total
    } = this.cartModel;

    return (
      applicableShippingMethods.pending ||
      shippingCost.pending ||
      linesDiscount.pending ||
      cartDiscount.pending ||
      discount.pending ||
      tax.pending ||
      total.pending ||
      this.chargedTotal.pending
    );
  }

  /**
   * Before using this property, check that the cart is
   * initialized by accessing the {@link isReady} property.
   *
   * While the `uuid` is available for convenience, it is not recommended
   * to use this property within React code.
   *
   * @throws An {@link UninitializedCartError} if the cart is not initialized.
   * @inheritdoc
   */
  public get uuid(): string {
    if (!this.cartModel) {
      throw new UninitializedCartError(
        'Cannot get cart UUID from an uninitialized cart.'
      );
    }
    return this.cartModel.uuid;
  }

  /**
   * Before using this property, check that the cart is
   * initialized by accessing the {@link isReady} property.
   *
   * While the `ownerID` is available for convenience, it is not recommended
   * to use this property within React code.
   *
   * @throws An {@link UninitializedCartError} if the cart is not initialized.
   * @inheritdoc
   */
  public get ownerID(): string {
    if (!this.cartModel) {
      throw new UninitializedCartError(
        'Cannot get cart owner ID from an uninitialized cart.'
      );
    }
    return this.cartModel.ownerID;
  }

  /** @inheritdoc */
  public get items(): Array<LineItemModel> {
    if (!this.cartModel) {
      return [];
    }
    return this.cartModel.items;
  }

  /**
   * The total number of items in the cart, that is,
   * the sum of the quantities of all line items.
   * @returns The total number of items in the cart.
   */
  public get totalItemQuantity(): number {
    if (!this.cartModel) {
      return 0;
    }

    return this.cartModel.totalItemQuantity;
  }

  /**
   * The list of products in the cart, that is,
   * excluding any non-product line items (e.g. Gift cards).
   * @returns The list of products in the cart.
   */
  public get products(): Array<ProductLineItemModel> {
    return this.items.filter(
      (item) => item.type === LineItemType.Product
    ) as Array<ProductLineItemModel>;
  }

  /**
   * The total number of products in the cart, that is,
   * the sum of the quantities of all product line items,
   * excluding any non-product line items (e.g. Gift cards).
   * @returns The total number of products in the cart.
   */
  public get totalProductQuantity(): number {
    return this.products.reduce((count, item) => count + item.quantity, 0);
  }

  /**
   * Checks if the cart is empty.
   * @returns Whether the cart is empty.
   */
  public get isEmpty(): boolean {
    return this.totalItemQuantity === 0;
  }

  /** @inheritdoc */
  public get total(): StaleWhileRevalidate<MoneyModel> {
    if (!this.cartModel) {
      return new StaleWhileRevalidate(MoneyModel.fromAmount(0));
    }

    // special case to show subtotal when cart is still loading totals
    if (
      this.cartModel.total.pending &&
      this.cartModel.total.value.isZero &&
      !this.isEmpty
    ) {
      return new StaleWhileRevalidate(
        this.cartModel.subtotal,
        this.cartModel.total
      );
    }

    return this.cartModel.total;
  }

  /** @inheritdoc */
  public get tax(): StaleWhileRevalidate<MoneyModel> {
    if (!this.cartModel) {
      return new StaleWhileRevalidate(MoneyModel.fromAmount(0));
    }
    return this.cartModel.tax;
  }

  /** @inheritdoc */
  public get subtotal(): MoneyModel {
    if (!this.cartModel) {
      return MoneyModel.fromAmount(0);
    }
    return this.cartModel.subtotal;
  }

  /** @inheritdoc */
  public get promotions(): ReadonlyArray<ICartPromotion> {
    if (!this.cartModel) {
      return [];
    }
    return this.cartModel.promotions;
  }

  /** @inheritdoc */
  public get coupons(): ReadonlyArray<ICoupon> {
    if (!this.cartModel) {
      return [];
    }
    return this.cartModel.coupons;
  }

  /** @inheritdoc */
  public get cartDiscount(): StaleWhileRevalidate<MoneyModel> {
    if (!this.cartModel) {
      return new StaleWhileRevalidate(MoneyModel.fromAmount(0));
    }
    return this.cartModel.cartDiscount;
  }

  /** @inheritdoc */
  public get linesDiscount(): StaleWhileRevalidate<MoneyModel> {
    if (!this.cartModel) {
      return new StaleWhileRevalidate(MoneyModel.fromAmount(0));
    }
    return this.cartModel.linesDiscount;
  }

  /** @inheritdoc */
  public get discount(): StaleWhileRevalidate<MoneyModel> {
    if (!this.cartModel) {
      return new StaleWhileRevalidate(MoneyModel.fromAmount(0));
    }
    return this.cartModel.discount;
  }

  /**
   * The total gift card amount **applied**. This is a **POSITIVE** value.
   * Multiply this by `-1` to get the difference on the cart total.
   * @returns The total gift card amount applied.
   */
  public get giftCardAmount(): MoneyModel {
    if (!this.cartModel) {
      return MoneyModel.fromAmount(0);
    }

    return this.cartModel.giftCardAmount;
  }

  /** @inheritdoc */
  public get shippingCost(): StaleWhileRevalidate<MoneyModel> {
    if (!this.cartModel) {
      return new StaleWhileRevalidate(MoneyModel.fromAmount(0));
    }

    return this.cartModel.shippingCost;
  }

  /** @inheritdoc */
  public get selectedShippingMethod(): Nullable<ShippingMethodModel> {
    if (!this.cartModel) {
      return null;
    }
    return this.cartModel.selectedShippingMethod;
  }

  /** @inheritdoc */
  public get shipToAddress(): Nullable<IAddress> {
    if (!this.cartModel) {
      return null;
    }
    return this.cartModel.shipToAddress;
  }

  /** @inheritdoc */
  public get applicableShippingMethods(): StaleWhileRevalidate<
    Array<ShippingMethodModel>
  > {
    if (!this.cartModel) {
      return new StaleWhileRevalidate([]);
    }
    return this.cartModel.applicableShippingMethods;
  }

  /**
   * The total of the cart that the user is charged including
   * shipping, discounts and tax prices **AND** gift cards.
   *
   * This total should **NOT** be used to construct the order object;
   * the handling of the charged amount occurs in DOMS.
   *
   * @returns The total of the cart that the user is charged.
   */
  public get chargedTotal(): StaleWhileRevalidate<MoneyModel> {
    if (!this.cartModel) {
      return new StaleWhileRevalidate(MoneyModel.fromAmount(0));
    }

    const amountRemaining = MoneyModel.subtract(
      this.total.value,
      this.giftCardAmount
    );

    const zeroMoney = MoneyModel.fromAmount(0);

    return new StaleWhileRevalidate(
      amountRemaining.isNegative ? zeroMoney : amountRemaining,
      this.total.then((newTotal) => {
        return MoneyModel.max(
          zeroMoney,
          MoneyModel.subtract(newTotal, this.giftCardAmount)
        );
      })
    );
  }

  /**
   * Whether gift card payments, coupons, and promotions add up to the full value of the cart.
   * @returns True if the cart total is greater than 0, and false otherwise.
   */
  public get isPaymentNecessary(): boolean {
    if (!this.cartModel) {
      return false;
    }

    const fixedTotal = this.chargedTotal.value.toFixed();
    return MoneyModel.from(fixedTotal).isPositive;
  }

  /** @inheritdoc */
  public get giftCards(): ReadonlyArray<GiftCardModel> {
    if (!this.cartModel) {
      return [];
    }
    return this.cartModel.giftCards;
  }

  /**
   * Whether the site should "refresh" the cart and retrieve the latest state
   * from the server on every page load. This is helpful in ensuring the cart held
   * in memory doesn't become stale or desynchronized with the server, which may occur
   * if the user has multiple tabs open or if the cart is updated from another source.
   * If it is false then cart will only fresh load on checkout and cart.
   * @returns Whether the cart should be refreshed on every page.
   */
  public get isReloadedOnEveryPage(): boolean {
    return this.cartConfig.getSetting('isReloadedOnEveryPage').value;
  }

  /**
   * On initial load should `revalidate` be awaited.
   * If this is true, then this can cause the first cart load
   * to be quite slow, but subsequent cart loads will be fast.
   * @returns Whether a full refresh should be awaited on initial load.
   */
  public get isInitialRevalidationLazy(): boolean {
    return this.cartConfig.getSetting('isInitialRevalidationLazy').value;
  }

  /**
   * Creates a new cart view model.
   * @param cartModel - The cart model to initialize the view model with.
   * A null value will act as an empty cart.
   */
  public constructor(cartModel?: Nullable<CartModel>) {
    this.cartModel = cartModel;
    makeAutoObservable(this);
  }

  /**
   * Validates the cart configuration settings.
   * @param config - The cart configuration settings to validate.
   * @throws An {@link InvalidStateError} if `maxQuantityPerLine` is not a positive integer.
   * @throws An {@link InvalidStateError} if `maxQuantityPerCart` is not a positive integer.
   * @throws An {@link InvalidStateError} if `maxQuantityPerLine` is greater than `maxQuantityPerCart`.
   */
  private validateCartConfig(config: Config<'cart'>): void {
    const maxQuantityPerLine = config.getSetting('maxQuantityPerLine').value;
    const maxQuantityPerCart = config.getSetting('maxQuantityPerCart').value;

    if (!Number.isInteger(maxQuantityPerLine) || maxQuantityPerLine <= 0) {
      throw new InvalidStateError(
        `The maximum quantity per line must be a positive integer. Received ${maxQuantityPerLine}.`
      );
    }

    if (!Number.isInteger(maxQuantityPerCart) || maxQuantityPerCart <= 0) {
      throw new InvalidStateError(
        `The maximum quantity per cart must be a positive integer. Received ${maxQuantityPerCart}.`
      );
    }

    if (maxQuantityPerLine > maxQuantityPerCart) {
      throw new InvalidStateError(
        `The maximum quantity per line must be less than or equal to the maximum quantity per cart. Received ${maxQuantityPerLine} and ${maxQuantityPerCart} respectively.`
      );
    }
  }

  /**
   * The maximum quantity (inclusive) of a single line item that can be added to the cart.
   * @returns The maximum quantity of a single line item that can be added to the cart.
   */
  public get maxQuantityPerLine(): number {
    return this.cartConfig.getSetting('maxQuantityPerLine').value;
  }

  /**
   * The maximum total quantity (inclusive) of items that can be added to the cart and
   * purchased in a single order.
   * @returns The maximum total quantity of items that can be added to the cart.
   */
  public get maxQuantityPerCart(): number {
    return this.cartConfig.getSetting('maxQuantityPerCart').value;
  }

  /**
   * Initializes or refreshes the underlying cart model from the server.
   * This should be called immediately after the cart view model is created.
   */
  public async refresh(): Promise<void> {
    if (this.cartModel) {
      try {
        // if the cart model is already initialized, then we need to refresh it
        const cartModel = await CartService.getCart(this.cartModel.uuid);

        const { uuid, items, coupons, giftCards } = cartModel.toDTO();

        // only update the following properties since they are the only ones
        // that are persisted on the server and can change from other sources
        this.cartModel.update({ uuid, items, coupons, giftCards });
        await this.cartModel.revalidate();
      } catch {
        this.cartModel = null;

        // if there was an error, then perhaps the user ID changed or the cart expired,
        // but either way, try getting the cart from the session data or create a new one
        await this._defaultRefresh();
      }

      return;
    }

    // if the cart model is not initialized, then we need to restore it
    await this._defaultRefresh();
  }

  /**
   * A utility for refreshing the underlying cart model using
   * the session data, or creating a new one if it doesn't exist.
   *
   * This is meant for internal use only.
   */
  private async _defaultRefresh(): Promise<void> {
    const cartModel = await CartService.getCart();

    if (this.isInitialRevalidationLazy) {
      cartModel.revalidate();
    } else {
      await cartModel.revalidate();
    }

    this.cartModel = cartModel;
    this._isPlaced = false;
  }

  /**
   * Replaces the current cart with a new, empty cart.
   */
  public async empty(): Promise<void> {
    this.cartModel = null; // let's us do some optimistic UI updates
    this._isPlaced = false;

    this.cartModel = await CartService.getNewCart();
    // Since the cart is guaranteed to be empty, we don't need to perform revalidation.
  }

  /**
   * Adds a line item to the cart.
   * @param product - The product to be added to this cart.
   * @param [quantity] - The quantity to add to the product.
   * @returns A promise that resolves as true if the operation was successful.
   */
  public async addItem(
    product: IProduct,
    quantity: number = 1
  ): Promise<ILineItem> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    return this.cartModel!.addItem(product, quantity);
  }

  /**
   * Deletes a line item in this cart.
   * @param lineItem - The `ILineItem` representation or line item UUID to be deleted.
   * @returns - Promise that resolves on successful operation.
   */
  public async removeItem(lineItem: ILineItem | string): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    await this.cartModel!.removeItem(lineItem);
  }

  /**
   * Replaces a line item in the cart with a new product.
   * @param previousLineItem - The {@link ILineItem} or line item UUID to be replaced.
   * @param newProduct - The new Product to be added to the cart.
   * @returns - Promise that resolves on successful operation.
   */
  public async replaceLineItem(
    previousLineItem: ILineItem,
    newProduct: IProduct
  ): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    await this.cartModel!.replaceLineItem(previousLineItem, newProduct);
  }

  /**
   * Updates a line item in this cart.
   * @param lineItem - The `ILineItem` representation or line item UUID to be updated.
   * @param quantity - The new quantity.
   * @returns Promise that resolves on successful operation.
   */
  public async updateLineItem(
    lineItem: ILineItem | string,
    quantity: number
  ): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    await this.cartModel!.updateLineItem(lineItem, quantity);
  }

  /**
   * Apply a coupon code to the cart.
   * @param code - Coupon code to apply.
   * @returns A promise that resolves on success.
   * @throws An Error if the supplied coupon code is not applicable.
   */
  public async applyCoupon(code: string): Promise<ICoupon> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    return this.cartModel!.applyCoupon(code);
  }

  /**
   * Remove a coupon code from the cart.
   * @param couponID - Coupon id to remove.
   * @returns A promise that resolves on success.
   * @throws An Error if the supplied coupon id is not applicable.
   */
  public async removeCoupon(couponID: string): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    return this.cartModel!.removeCoupon(couponID);
  }

  /**
   * Adds a gift card to the carts gift card array.
   * @param giftCard - The gift card you want to add to the cart.
   * @returns A promise that resolves on success.
   * @throws An Error if the supplied gift card is not applicable.
   */
  public async addGiftCard(giftCard: IGiftCardBase): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    return this.cartModel!.addGiftCard(giftCard);
  }

  /**
   * Removes a gift card from the giftCards array by its number.
   * @param giftCardID - The gift card id of the card to remove.
   * @returns A promise that resolves on success.
   * @throws An Error if the supplied gift card id is not applicable.
   */
  public async removeGiftCard(giftCardID: string): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    return this.cartModel!.removeGiftCard(giftCardID);
  }

  /**
   * Selects a shipping method for this cart.
   *
   * @param shippingMethod - The shipping method to select. Should be either a
   * {@link ShippingMethodModel} or a shipping method UID.
   *
   * @throws An {@link InvalidArgumentError} if the provided shipping method is
   * not applicable to this cart.
   */
  public async selectShippingMethod(
    shippingMethod: ShippingMethodModel | string
  ): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    await this.cartModel!.selectShippingMethod(shippingMethod);
  }

  /**
   * Sets the address the cart will be shipped to if checked out. Useful for
   * calculating taxes and determining available shipping methods.
   *
   * @param address - The address to set.
   */
  public async setShipToAddress(address: IAddress): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    await this.cartModel!.setShipToAddress(address);
  }

  /**
   * Places a default order with the current cart using
   * the provided shipping and payment information.
   * @param shippingForm - The shipping form information.
   * @param paymentForm - The payment form information.
   * @param browserData - The user's browser data at the time of place order.
   * @returns A placed order model; effectively a summary of the order.
   * @throws An {@link UninitializedCartError} if the cart is empty.
   */
  public async placeDefaultOrder(
    shippingForm: ShippingFormModel,
    paymentForm: PaymentFormModel,
    browserData: IBrowserData
  ): Promise<PlacedOrderModel> {
    if (this.isEmpty) {
      throw new UninitializedCartError('Cannot place order with empty cart.');
    }

    const order = await PlaceOrderService.placeDefaultOrder(
      shippingForm.toOrderShippingInfo(),
      paymentForm.address,
      paymentForm.toPaymentInfo(),
      this.cartModel!,
      browserData
    );

    this._isPlaced = true;

    UserInteractionService.makeAction({
      action: EventType.OrderSuccess,
      orderData: order.toDTO(),
      cartData: this.cartModel!.toDTO()
    });

    return order;
  }

  /**
   * Places a default order with the current cart using
   * the provided shipping and payment information.
   * @param shippingForm - The shipping form information.
   * @param billingAddress - The billing address information.
   * @param encryptedCardNumber - The encrypted card number.
   * @param browserData - The user's browser data at the time of place order.
   * @param agentType - The type of user placing the order.
   * @returns A placed order model; effectively a summary of the order.
   * @throws An {@link UninitializedCartError} if the cart is empty.
   */
  public async placeMagtekOrder(
    shippingForm: ShippingFormModel,
    billingAddress: IAddress,
    encryptedCardNumber: string,
    browserData: IBrowserData,
    agentType: EndlessAisleAgent
  ): Promise<PlacedOrderModel> {
    if (this.isEmpty) {
      throw new UninitializedCartError('Cannot place order with empty cart.');
    }

    const order = await PlaceOrderService.placeMagtekOrder(
      shippingForm.toOrderShippingInfo(),
      billingAddress,
      encryptedCardNumber,
      this.cartModel!,
      browserData,
      agentType
    );

    this._isPlaced = true;

    UserInteractionService.makeAction({
      action: EventType.OrderSuccess,
      orderData: order.toDTO(),
      cartData: this.cartModel!.toDTO()
    });

    return order;
  }

  /**
   * Places a PayPal order with the current cart, using the provided shipping and payment
   * information, and the checkout details returned by PayPal.
   *
   * @param expressCheckoutDetails - The details returned by the
   * [`GetExpressCheckoutDetails`](https://developer.paypal.com/api/nvp-soap/get-express-checkout-details-nvp/)
   * call after approval.
   *
   * @param browserData - The user's browser data at the time of place order.
   * @param shippingForm - The shipping form information.
   * @param billingAddress - The billing address information.
   *
   * @returns A placed order model; effectively a summary of the order.
   * @throws An {@link UninitializedCartError} if the cart is empty.
   */
  public async placePayPalOrder(
    expressCheckoutDetails: IGetExpressCheckoutDetailsResponse,
    browserData: IBrowserData,
    shippingForm?: ShippingFormModel,
    billingAddress?: IAddress
  ): Promise<PlacedOrderModel> {
    if (this.isEmpty) {
      throw new UninitializedCartError('Cannot place order with empty cart.');
    }

    const model = CartModel.from(this.cartModel!.toDTO());

    const paypalShippingAddress =
      PayPalNVPService.getShippingAddressFromExpressCheckoutDetails(
        expressCheckoutDetails
      );

    // If an address was entered on PayPal...
    if (paypalShippingAddress) {
      // ...then this probably means that PayPal was given the responsibility of
      // taking the user's shipping address via their modal. This happens with
      // Express Checkout carts.
      //
      // So, use that address to update the cart and revalidate.
      await model.setShipToAddress(paypalShippingAddress);
    }

    // Note that on Express Checkout, the billing address will be the same as the
    // shipping address entered by the user on the PayPal modal.
    const billingAddressToUse =
      billingAddress ??
      PayPalNVPService.getBillingAddressFromExpressCheckoutDetails(
        expressCheckoutDetails
      );

    if (!billingAddressToUse) {
      // Neither a billing address was provided, nor PayPal provided one. Throw.
      throw new InvalidStateError(
        'Cannot place PayPal order: A billing address was not provided.'
      );
    }

    // If shipping info wasn't provided, then get it from the PayPal response.
    // This also happens with Express Checkout.
    const shippingInfo = shippingForm
      ? shippingForm.toOrderShippingInfo()
      : // This will throw if the details don't have an address.
        PayPalNVPService.getOrderShippingInfoFromExpressCheckoutDetails(
          expressCheckoutDetails,
          model
        );

    const order = await PlaceOrderService.placePayPalOrder(
      // Only send the details required to make the order to reduce payload size
      {
        ACK: expressCheckoutDetails.ACK,
        TOKEN: expressCheckoutDetails.TOKEN,
        PAYERID: expressCheckoutDetails.PAYERID,
        EMAIL: expressCheckoutDetails.EMAIL,
        PAYERSTATUS: expressCheckoutDetails.PAYERSTATUS,
        PAYMENTREQUEST_0_ADDRESSSTATUS:
          expressCheckoutDetails.PAYMENTREQUEST_0_ADDRESSSTATUS,
        PAYMENTREQUEST_0_ALLOWEDPAYMENTMETHOD:
          expressCheckoutDetails.PAYMENTREQUEST_0_ALLOWEDPAYMENTMETHOD,
        COUNTRYCODE: expressCheckoutDetails.COUNTRYCODE,
        CORRELATIONID: expressCheckoutDetails.CORRELATIONID
      },
      shippingInfo,

      billingAddressToUse,
      model,
      browserData
    );

    this._isPlaced = true;

    UserInteractionService.makeAction({
      action: EventType.OrderSuccess,
      orderData: order.toDTO(),
      cartData: this.cartModel!.toDTO()
    });

    return order;
  }

  /**
   * Tries to merge the current cart with the given user's active cart.
   * If the user does not have an active cart, a new cart is created.
   *
   * Upon successful merge, the current cart is updated to the merged cart.
   * If the merge fails, the current cart is not updated.
   *
   * @param user - The authenticated user whose cart to merge with.
   * @returns A promise that resolves to `true` if the merge was successful, and `false` otherwise.
   */
  public async tryMergeWithUserCart(
    user: IAuthenticatedUser
  ): Promise<boolean> {
    try {
      if (!this.cartModel) {
        await this.untilReady;
      }

      const userCartID = user.account.activeCartID;
      if (userCartID) {
        await this._mergeWith(userCartID);
      } else {
        await this._copyToNewCart();
      }
      return true;
    } catch (err) {
      // first log the error
      LoggerService.error(
        new Error('There was an error merging carts.', {
          cause: err as Error
        })
      );

      // If the cart merge fails, just ignore it and proceed as normal.
      // The user will still be logged in and redirected to the correct page.
      return false;
    }
  }

  /**
   * Copies the current cart to a new cart, and
   * updates the current cart to the new cart.
   */
  public async copyToNewCart(): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    await this._copyToNewCart();
  }

  /**
   * Copies the current cart to a new cart, skipping validation.
   * Updates the current cart to the new cart.
   */
  private async _copyToNewCart(): Promise<void> {
    const cart = await CartService.getNewCart();
    try {
      await this._mergeWith(cart.uuid);
    } catch (error) {
      // If the merge fails, make sure to set the current cart to the new cart...
      this.cartModel = cart;
      // ...and rethrow the error.
      throw error;
    }
  }

  /**
   * Merges the current cart with another cart.
   * Updates the current cart to the cart with the given ID.
   * @param cartID - The UUID of the cart to merge with.
   * @throws An {@link UninitializedCartError} if the cart is not initialized.
   * @throws An error if the merge fails.
   */
  public async mergeWith(cartID: string): Promise<void> {
    if (!this.cartModel) {
      await this.untilReady;
    }
    await this._mergeWith(cartID);
  }

  /**
   * Merges the current cart with another cart, skipping validation.
   * Updates the current cart to the cart with the given ID.
   * @param cartID - The UUID of the cart to merge with.
   * @throws An {@link UninitializedCartError} if the cart is not initialized.
   * @throws An error if the merge fails.
   */
  private async _mergeWith(cartID: string): Promise<void> {
    const destinationCart = await CartService.mergeCarts(
      this.cartModel!.uuid,
      cartID
    );
    await destinationCart.revalidate();
    this.cartModel = destinationCart;
  }

  /**
   * Creates a copy of the cart view model.
   * @param cart - The cart to copy.
   * @returns A new cart view model.
   */
  public static from(cart: CartVM): CartVM {
    const newCart = new CartVM(cart.cartModel);
    newCart._isPlaced = cart._isPlaced;
    return newCart;
  }

  /**
   * Places an Apple Pay order with a copy of the current cart.
   *
   * This **must not** be called if the cart is not ready.
   *
   * @returns A placed order model; effectively a summary of the order.
   * @throws An {@link UninitializedCartError} if the cart is not initialized.
   */
  public async orderWithApplePay(): Promise<PlacedOrderModel> {
    if (!this.cartModel) {
      /**
       * Instantiating an `ApplePaySession` requires basic cart data such as the order total.
       * As a result, we cannot create a session with an uninitialized cart. However, an
       * `ApplePaySession` may only be created **synchronously** from a user gesture.
       * This means we cannot have `await this.untilReady`, or any other async logic before
       * creating the session. So, we have no choice but to throw an error here.
       */
      throw new UninitializedCartError(
        'Cannot initialize an Apple Pay session with an uninitialized cart.'
      );
    }

    if (this.isEmpty) {
      throw new ForbiddenActionError('Cannot request payment with empty cart.');
    }

    // Create a new cart to track its state separately during the payment request session
    const sessionCart = new CartModel(this.cartModel.toDTO());

    const order = await PaymentRequestService.requestApplePayPayment(
      sessionCart,
      async (expressPayment) => {
        const { shippingInfo, billingAddress, data } = expressPayment;
        const browserData = getBrowserData();

        return PlaceOrderService.placeApplePayOrder(
          shippingInfo,
          billingAddress,
          data,
          sessionCart,
          browserData
        );
      }
    );

    this._isPlaced = true;

    UserInteractionService.makeAction({
      action: EventType.OrderSuccess,
      orderData: order.toDTO(),
      cartData: sessionCart.toDTO()
    });

    return order;
  }

  /**
   * Creates a DTO from this CartVM.
   * @returns An {@link ICart} DTO.
   */
  public toDTO(): Nullable<DTO<ICart>> {
    return this.cartModel?.toDTO();
  }
}
