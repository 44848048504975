import { createContext, useContext } from 'react';

/**
 * A function to revalidate the global cart, by fetching
 * the latest data from the server.
 *
 * This is similar to the `CartVM.refresh()` method, but
 * with added support for React's concurrent mode.
 */
export type RevalidateCartFn = () => void;

/**
 * A context that provides a function to revalidate the global
 * cart by fetching the latest data from the server.
 *
 * The function is similar to the `CartVM.refresh()` method, but
 * with added support for React's concurrent mode.
 */
export const RevalidateCartContext = createContext<RevalidateCartFn>(() => {});

/**
 * A hook for getting a function to revalidate the global cart.
 *
 * This is similar to the `CartVM.refresh()` method, but
 * with added support for React's concurrent mode.
 *
 * **Note**: For now, this will also reset the `CartVM.isPlaced`
 * to `false` since that state is maintained locally only.
 *
 * @returns A function to revalidate the global cart.
 */
export function useRevalidateCart(): RevalidateCartFn {
  return useContext(RevalidateCartContext);
}
